import { CurrentUser, Role } from "@sanity/types";

export const isE2eTestEmail = (email?: string): boolean => !!email?.match(/e2e-test/i);
export const isBibsentEmail = (email?: string): boolean => !!email?.match(/@bibsent.no$/i);
export const isRediaEmail = (email?: string): boolean => !!email?.match(/@redia.dk$/i);

export const isGlobalAdmin = ({ roles }: { roles?: Role[] }): boolean =>
  !!roles?.find(({ name }) => name === "administrator");

export const isGlobalEditor = ({ roles }: { roles?: Role[] }): boolean =>
  !!roles?.find(({ name }) => name === "editor");

export const isTestUser = ({ name }: CurrentUser): boolean => !!name.match(/testbruker/i);
